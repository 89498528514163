<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Games from "@/services/Games";
import Swal from "sweetalert2";
import _ from 'underscore';
import ModalUploadWords from  "@/components/modals/games/modalUploadWords";

export default {
    components: { Layout, PageHeader, ModalUploadWords },
    page: {
        title: "Word Game",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    async created() {
        this.getWords();
    },

    data() {
        return {
            title: "Words",
            items: [
                {
                    text: "Fan Experience",
                },
                {
                    text: "Social",
                    active: true,
                },
            ],

            error: null,
            showModal: false,
            modalData: {},
            tableData: [],
            showErrorMessage: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: ['language', 'word'],
            sortBy: "word",
            showTable: true,
            sortDesc: false,
            removeExisting: 0,
            showLoader: false,
            fields: [
                {
                    key: "language",
                    label: "Language",
                    sortable: true,
                },
                {
                    key: "word",
                    label: "Word",
                    sortable: false,
                },
                {
                   key: "Action",
                   label: "Action",
                   thStyle: { width: "12%" },
                }

            ],
            isBusy: false,
        };
    },
    computed: {

        rows() {
            return this.tableData.length;
        },
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods: {
        async getWords() {
            try {
                this.toggleBusy();
                const response = await Games.getAllWords([]);
                this.tableData = response.data.data;
                this.totalRows = this.tableData.length;
                this.toggleBusy();
                this.toggleTableVisibility(); // Show the table
            } catch (error) {
                this.toggleBusy();
                this.error = error.response?.data?.error || error.message;
                this.tableData = [];
            }
        },

       removeWord(language, word){
            Swal.fire({
              title: "Are you sure?",
              text: "Remove Word!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes!",
            }).then((result) => {
              if (result.value) {
                    Games.deleteWord(language, word)
                        .then((response) => {
                          const res   = response.data.success  ? response.data.success  : false;
                          const error = response.data.error ? response.data.error : '';
                          if(res && error==''){
                            this.getWords();
                            this.successmsg("Word Successfully Removed")
                          }else{
                            this.failedmsg("Word Removal Failed")
                          }
                        })
                        .catch(error => {
                          this.failedmsg('Fail!',error)
                    });
              }
            });
        },

        onFiltered(filteredItems) {
             this.totalRowsAll = filteredItems.length;
             this.currentPage = 1;
           },
        toggleTableVisibility() {
            this.showTable = true; // Show the table
        },

        toggleBusy() {
            this.isBusy = !this.isBusy;
        },

        callModalUploadWords() {
            this.$bvModal.show("upload_words_from_file");
        },
    },
    middleware: "authentication",
};
</script>

 <template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-if="showTable">
                     <div class="row mb-3">
                        <div class="col-md-6">
                                <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalUploadWords">
                                    <i class="mdi mdi-upload me-2"></i>
                                    Upload File
                                </a>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="tableData"
                                :busy="isBusy"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                               :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                show-empty
                                empty-text="No Data Found"
                            >
                             <template v-slot:cell(action)="data">
                                  <ul class="list-inline mb-0">
                                    <li class="list-inline-item">
                                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Word" @click="removeWord(data.item.language, data.item.word)">
                                        <i class="uil uil-trash-alt font-size-18"></i>
                                      </a>
                                    </li>

                                  </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-black my-2">
                                        <b-spinner class="align-middle text-primary"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info">
                                    Showing {{ (currentPage - 1) * perPage + 1 }} to
                                    {{ (currentPage - 1) * perPage + rows }} of {{ rows }} entries
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="right"
                                    size="sm"
                                ></b-pagination>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    <!-- MODALS-->
        <ModalUploadWords @onRefresh="getWords()"></ModalUploadWords>
    <!--MODALS -->

    </Layout>
</template>
