<script>
    import Games from '@/services/Games';
    import Monitor from '@/services/Games';
    import { required, maxLength } from "vuelidate/lib/validators";

    import validationMessages from '@/components/validations';
    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                showLoader: false,
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                fileContent: '',
                languages:['nl'],
                language: 'nl',
                removeExisting:0,
                Data: [],
                csrf_token: localStorage.getItem('csrf_token'),
            };
        },
     validations: {
        file: {
            required
        }

      },


      methods: {
            refreshData() {
              this.$emit('onRefresh');
            },

            importFile(event) {
              const file = event.target.files[0];
              if (file && file.type === "text/plain") {
                const reader = new FileReader();
                reader.onload = (e) => {
                  this.fileContent = e.target.result;
                };
                reader.readAsText(file);
              } else {
                alert("Please upload a valid .txt file");
              }
            },

            async uploadFile() {
                this.tryingToSubmit = true;
                this.submitted = true;
                this.showLoader = true;

                var data = {remove_existing:this.removeExisting, words:this.fileContent, language:this.language }

                Games.uploadWords(data)
                     .then((response) => {
                       const res   = response.data.success  ? response.data.success  : false;
                       const error = response.data.error ? response.data.error : '';
                       if(res && error==''){
                          this.refreshData();
                          this.closeModal();
                          this.successmsg("Words Successfully Uploaded")
                          this.tryingToSubmit = false;
                          this.showLoader = false;
                          this.submitted = false;
                       }else{
                         this.failedmsg("Words Upload Failed")
                         this.tryingToSubmit = false;
                         this.showLoader = false;
                         this.submitted = false;
                       }
                     })
                     .catch(error => {
                       this.failedmsg('Fail!',error)
                       this.tryingToSubmit = false;
                       this.showLoader = false;
                       this.submitted = false;
                 });
                },

            closeModal() {
              this.showModal = false;
            },

            resetProps() {
              this.submitted = false;
              this.tenant = '';
              this.dashboard_url = '';
            }

      },

    };
</script>

<template>
    <b-modal id="upload_words_from_file" v-model="showModal" @hidden="resetProps" title="Upload Words" title-class="font-18" >
        <form @submit.prevent="createTenantDashboard" v-if="!showLoader">
            <div class="row">
              <div class="col-6">
                <b-form-group label="Text File" label-for="formrow-title-input" class="mb-3">
                      <input id="file" type="file" @change="importFile" accept=".txt"
                         :class="{ 'is-invalid': submitted && $v.file.$error }"/>
                       <validationMessages v-if="submitted" :fieldName="'File'" :validationName="$v.file"></validationMessages>
                </b-form-group>

                <b-form-group label="Select Language" label-for="formrow-tenant-input" class="mb-3">
                  <b-form-select
                      v-model="language"
                      :options="languages"
                      class="form-select"
                  ></b-form-select>
                </b-form-group>

                 <b-form-group label="Remove all existing words" label-for="formrow-tenant-input" class="mb-6">
                    <input type="checkbox" id="removeExisting" class="form-check-input" true-value="1" false-value="0" v-model="removeExisting">
                </b-form-group>
              </div>
            </div>
        </form>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <div class="row">
                    <div class="col-12">
                        <div class="row  align-items-center justify-content-center">
                            <b-spinner large></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="uploadFile" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>
